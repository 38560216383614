<template>
  <div>
    <q-form ref="editSopStepForm">
      <c-card :title="'['+sopStep.jobStepName+'] '+ $language('작업단계 상세정보')" class="cardClassDetailForm no-margin">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn 
              v-show="editable" 
              :isSubmit="isSaveSopStep"
              :url="saveSopStepUrl"
              :param="sopStep"
              :mappingType="mappingType"
              label="저장" 
              icon="save"
              @beforeAction="saveSopStep"
              @btnCallback="saveSopStepCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-5">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <!-- 사진 -->
                <c-upload-picture
                  height="300px"
                  :attachInfo="attachSopStepInfo"
                  :editable="editable">
                </c-upload-picture>
              </div>
              <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                <!-- 작업단계명 -->
                <c-text
                  required
                  :editable="editable"
                  label="작업단계"
                  name="jobStepName"
                  v-model="sopStep.jobStepName">
                </c-text>
              </div>
              <div class="col-2">
                <!-- 작업단계 번호 -->
                <c-text
                  required
                  :editable="editable"
                  type="number"
                  label="번호"
                  name="jobStepNo"
                  v-model="sopStep.jobStepNo">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 작업단계 설명 -->
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="설명"
                  name="jobStepDesc"
                  v-model="sopStep.jobStepDesc">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 안전보호구 -->
                <c-textarea
                  :editable="editable"
                  label="안전보호구"
                  name="saftyShied"
                  v-model="sopStep.saftyShied">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 사용공구 -->
                <c-textarea
                  :editable="editable"
                  label="사용공구"
                  name="useTool"
                  v-model="sopStep.useTool">
                </c-textarea>
              </div>
              <div class="col-12">
                <!-- 주의사항 -->
                <c-textarea
                  :editable="editable"
                  label="주의사항"
                  name="caution"
                  v-model="sopStep.caution">
                </c-textarea>
              </div>
            </div>
          </div>

          <div class="col-7">
            <!-- 유해위험요인 목록 -->
            <c-table
              ref="tableHazard"
              title="유해위험요인 목록"
              :editable="editable"
              :columns="gridHazard.columns"
              :merge="gridHazard.merge"
              :data="sopStep.sopHazardFactors"
              gridHeight="650px"
              selection="multiple"
              rowKey="sopStepHazardId"
            >
              <template slot="table-button">
                <q-btn-group outline>
                          <c-btn 
                    v-if="editable" 
                    label="추가" 
                    icon="add" 
                    @btnClicked="addSopHazard" />
                  <c-btn 
                    v-if="editable" 
                    label="제외" 
                    icon="remove" 
                    @btnClicked="deleteSopHazard" />
                </q-btn-group>
              </template>
              <template v-slot:customArea="{ props, col }">
                <q-item v-if="col.name === 'item'" class="card-sop-step-list">
                  <q-item-section>
                    <q-item-label class="sopStep-card-title">
                      <q-btn
                        v-if="props.row.editFlag !== 'C'"
                        class="tableinnerBtn"
                        flat
                        :align="col.align"
                        color="blue-6"
                        :label="`${props.row['ramRiskHazardClassName']} / ${props.row['riskHazardName']}`"
                        @click.stop="openHazardCasue(props.row)" />
                      <template v-else>
                        {{`${props.row['ramRiskHazardClassName']} / ${props.row['riskHazardName']}`}}
                        <q-badge color="red">
                          NEW
                        </q-badge>
                      </template>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </c-table>
          </div>
        </template>
      </c-card>
    </q-form>

    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sopStepInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        jobStepId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      gridHazard: {
        merge: [
          { index: 0, colName: 'sopStepHazardId' }
        ],
        columns: [
          {
            name: 'item',
            field: 'item',
            label: '분류/유해위험요인',
            align: 'left',
            sortable: false,
            type: 'custom',
            style: 'width: 200px'
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '원인',
            align: 'left',
            sortable: false,
            style: 'width: 100px'
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            sortable: false,
            style: 'width: 100px'
          },
        ],
      },
      sopStep: {
        jobStepId: '',  // 작업단계일련번호
        mdmSopId: '',  // 안전작업 표준 일련번호
        jobStepNo: '',  // 작업단계 번호
        jobStepName: '',  // 작업단계 명
        jobStepDesc: '',  // 작업단계 설명
        saftyWorkMethod: '',  // 안전작업방법(주의사항)
        saftyShied: '',  // 안전보호구 수기 입력
        useTool: '',  // 사용공구
        caution: '',  // 주의사항
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 등록자 ID
        editFlag: '',
        sopHazards: [],
        deleteSopHazards: [],
        show: false
      },
      attachSopStepInfo: {
        isSubmit: '',
        taskClassCd: 'SOP_STEP_PHOTO',
        taskKey: '',
      },
      preview: {
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        fileExt: '',
      },
      mappingType: 'PUT',
      editable: true,
      isSaveSopStep: false,
      saveUrl: '',
      sopStepDetailUrl: '',
      saveSopStepUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setSopStepheight() {
      let height = this.contentHeight - 200;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.sopStepDetailUrl = selectConfig.mdm.sop.step.get.url;
      this.saveSopStepUrl = transactionConfig.mdm.sop.step.update.url;
      // code setting
      // list setting
      this.getSopStepDetail()
    },
    getSopStepDetail() {
      if (this.popupParam.jobStepId) {
        this.$http.url = this.$format(this.sopStepDetailUrl, this.popupParam.jobStepId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.sopStep, _result.data)
          this.$set(this.attachSopStepInfo, 'taskKey', this.popupParam.jobStepId)
        },);
      } else {
        // 신규등록
        this.$set(this.sopStep, 'mdmSopId', this.popupParam.mdmSopId)
      }
    },
    saveSopStep() {
      if (this.popupParam.jobStepId) {
        this.saveSopStepUrl = transactionConfig.mdm.sop.step.update.url;
        this.mappingType = 'PUT'
      } else {
        this.saveSopStepUrl = transactionConfig.mdm.sop.step.insert.url;
        this.mappingType = 'POST'
      }
      this.$refs['editSopStepForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.sopStep.regUserId = this.$store.getters.user.userId;
              this.sopStep.chgUserId = this.$store.getters.user.userId;

              this.isSaveSopStep = !this.isSaveSopStep;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSopStepCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.jobStepId = result.data
      this.attachSopStepInfo.taskKey = result.data
      this.attachSopStepInfo.isSubmit = uid()
      this.getSopStepDetail();
    },
    addSopHazard() {
      this.popupOptions.title = '유해위험요인 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.sopStep.sopHazards) this.sopStep.sopHazards = [];
        if (!this.sopStep.sopHazardFactors) this.sopStep.sopHazardFactors = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.sopStep.sopHazards, { mdmHazardFactorsId: item.ramRiskHazardId }) === -1) {
            this.sopStep.sopHazards.push({
              sopStepHazardId: uid(),  // 작업단계별 유해위험요인 일련번호
              mdmSopId: this.popupParam.mdmSopId, // 안전작업 표준 일련번호
              jobStepId: this.popupParam.jobStepId, // 작업단계 일련번호
              mdmHazardFactorsId: item.ramRiskHazardId, // 유해위험요인 일련번호
              riskHazardName: item.riskHazardName,
              ramRiskHazardClassCd: item.ramRiskHazardClassCd,
              ramRiskHazardClassName: item.ramRiskHazardClassName,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            });
            // this.$set(this.sopStep.sopHazardFactors, this.sopStep.sopHazardFactors.length, {
            //   sopStepHazardId: uid(),  // 작업단계별 유해위험요인 일련번호
            //   mdmSopId: this.popupParam.mdmSopId, // 안전작업 표준 일련번호
            //   jobStepId: this.popupParam.jobStepId, // 작업단계 일련번호
            //   mdmHazardFactorsId: item.ramRiskHazardId, // 유해위험요인 일련번호
            //   riskHazardName: item.riskHazardName,
            //   ramRiskHazardClassCd: item.ramRiskHazardClassCd,
            //   ramRiskHazardClassName: item.ramRiskHazardClassName,
            //   hazardOccurReason: '',
            //   hazardOccurResult: '',
            //   regUserId: this.$store.getters.user.userId,
            //   editFlag: 'C',
            // })
            this.sopStep.sopHazardFactors.splice(0, 0, {
              sopStepHazardId: uid(),  // 작업단계별 유해위험요인 일련번호
              mdmSopId: this.popupParam.mdmSopId, // 안전작업 표준 일련번호
              jobStepId: this.popupParam.jobStepId, // 작업단계 일련번호
              mdmHazardFactorsId: item.ramRiskHazardId, // 유해위험요인 일련번호
              riskHazardName: item.riskHazardName,
              ramRiskHazardClassCd: item.ramRiskHazardClassCd,
              ramRiskHazardClassName: item.ramRiskHazardClassName,
              hazardOccurReason: '',
              hazardOccurResult: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            });
          }
        })
        this.$refs['tableHazard'].reRend()
        // this.$forceUpdate();
      }
    },
    deleteSopHazard() {
      let selectData = this.$refs['tableHazard'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.sopStep.deleteSopHazards) this.sopStep.deleteSopHazards = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.sopStep.deleteSopHazards, { sopStepHazardId: item.sopStepHazardId }) === -1) {
                  this.sopStep.deleteSopHazards.push(item)
              }
              this.sopStep.sopHazards = this.$_.reject(this.sopStep.sopHazards, { mdmHazardFactorsId: item.mdmHazardFactorsId });
              this.sopStep.sopHazardFactors = this.$_.reject(this.sopStep.sopHazardFactors, item);
            })
            this.$refs['tableHazard'].$refs['compo-table'].clearSelection();
            this.$forceUpdate();
            // window.getApp.$emit('APP_REQUEST_SUCCESS');
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openHazardCasue(hazard) {
      this.popupOptions.title = '유해위험요인별 가능한 원인';
      this.popupOptions.param = {
        mdmSopId: hazard.mdmSopId,
        jobStepId: hazard.jobStepId,
        sopStepHazardId: hazard.sopStepHazardId,
        mdmHazardFactorsId: hazard.mdmHazardFactorsId,
        gridTitle: `${hazard.ramRiskHazardClassName} / ${hazard.riskHazardName}`
      };
      this.popupOptions.target = () => import(`${'./sopHazardFactorPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardCausePopup;
    },
    closeHazardCausePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getSopStepDetail()
    },
  }
};
</script>
<style lang="sass">
.sopStep-card-title
  margin-left: -5px
  font-size: 0.8rem
.card-sop-step-list
  padding: 0px 16px !important
  min-height: 28px
.hazard-factor-add-btn
  .q-btn__content
    padding: 0 !important
</style>